import React, { Fragment, useState } from 'react';
import { Image, Main, Header_Log, ContainerHeader, HeaderText, ContainerDivHeader, Button, ContainerHeaderMobile,MenuWrapper, Menu ,MenuItem, ButtonLink} from './containers/common.styled';
import ImageLogo from './../assets/img/isotipo-color.png';
import BurguerMenu from './../assets/img/menu.png';

const Header = () => {
    const [open, setOpen] = useState(false);
    
    const handleClick = () => {
        console.log("Entro a click")
        if(open)
        {
            setOpen(false)
        }
        else{
            setOpen(true)
        }
            
    }

   return (
        <Fragment>
            <Main padding="1rem">
                <ContainerHeader>
                    <div className="m-auto">
                            <Image src={ImageLogo} width='20%' alt="logo-coru" margin="inherit" ml="4%"/>
                    </div>
                    <Header_Log className="text-session">
                        <ContainerDivHeader>
                            <HeaderText href="#divStart">Home</HeaderText>
                            <HeaderText href="#divService">Services</HeaderText>
                            <HeaderText href="#divAbout">About</HeaderText>
                            <ButtonLink href="#divForm" mt="0%" mb="0px" fs="12px" width="30%" height="40px" ml="5%">REQUEST A QUOTE</ButtonLink>
                        </ContainerDivHeader>
                    </Header_Log>
                </ContainerHeader>
                <ContainerHeaderMobile>
                    <div className="m-auto">
                        <Image src={ImageLogo} width='52%' alt="logo-coru" marginMobile="inherit" mlMobile="-17%!important" />
                    </div>
                    <Header_Log  id="divmenumobile" className="text-session"  onClick ={handleClick}  >
                        <Image src={BurguerMenu} width='10%' alt="logo-coru" widthMobile="30px" marginMobile="inherit" mlMobile="auto" />
                        <MenuWrapper open={open} >
                                <MenuItem href='/'>Home</MenuItem>
                                <MenuItem href='/'>Services</MenuItem>
                                <MenuItem href='/'>About</MenuItem>
                        </MenuWrapper>
                    </Header_Log>
                </ContainerHeaderMobile>
            </Main>
        </Fragment>
    )
};

export default Header;
