import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"
import Home from "./components/Home"
import Home_2 from "./components/Home_2"
import Header from "./components/Header"
import emailjs from "emailjs-com"
function App() {
  return (
    <Router>
      <Header/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/Home2/" component={Home_2}/>
      </Switch>
    </Router>
  );
}

export default App;
