import React, { Fragment, useState, useEffect, useCallback} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Dialog, DialogTitle } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { render } from 'react-dom'
import { useTrail, animated, useSpring } from 'react-spring'
import {Title1, ButtonLink , Container, Main, Col6, ParagraphOne, ParagrapTwo, Button,Image, Col12, Col3, SpanCoru,Col3Contor, Linktext, Spanticons,ButtonWhatsApp,LogoWhats,HexagonoCard} from '../components/containers/common.styled';
import ImageLogo from './../assets/img/imagen1.png';
import ImageTwo from './../assets/img/imagen2.png';
import Imagebulb from './../assets/img/icons-08.png';
import Imagepen from './../assets/img/icons-06.png';
import Imagedesk from './../assets/img/icons-03.png';
import Imagemobile from './../assets/img/icons-02.png';
import Imagegrafic from './../assets/img/icons-09.png';
import Imagecircle from './../assets/img/icons-04.png';
import Imageprem from './../assets/img/icons-07.png';
import Imagecall from './../assets/img/icons-05.png';
import ImageThre from './../assets/img/imagen3.png';
import ImagemobileCoru from './../assets/img/mobileCoru.png';
import ImagePranaCompu from './../assets/img/PranaCompu.png';
import ImageCrezeTablet from './../assets/img/CrezeTablet.png';
import Imagevinetas11 from './../assets/img/vinetas-11.png';
import Imagevinetas10 from './../assets/img/vinetas-10.png';
import Imagevinetas13 from './../assets/img/vinetas-13.png';
import Imagevinetas12 from './../assets/img/vinetas-12.png';
import Imageboard from './../assets/img/board.png';
import ImageCoffe from './../assets/img/imagen4.png';
import './../assets/css/home.css';
import emailjs from "emailjs-com";
import {BtnGeneral} from './BtnStyle'
import { findByLabelText } from "@testing-library/react"
import Backdrop from "@material-ui/core/Backdrop";
import { isMobile } from 'react-device-detect'


const getWidth=()=> window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const useSizeDimmensions=()=>{
    const [size,setSize]=useState(getWidth()>890?0.19:
                                  getWidth()<890 && getWidth()>800?
                                  0.24:0.5);
    useEffect(()=>{
        let timeoutId=null;
        const resizeListener=()=>{
            clearTimeout(timeoutId);
            console.log("width",getWidth())
            timeoutId=setTimeout(()=>{
                if(getWidth()>890){
                    setSize(0.19);
                }
                else if(getWidth()>800 && getWidth()<890){
                    setSize(0.24)
                    console.log(0.24)
                }
                else if (getWidth()<800){
                    setSize(0.5);
                }
            },50);
            
        };
        window.addEventListener('resize',resizeListener);
        return()=>window.removeEventListener('resize',resizeListener);
    },[]);
    return size;
}
const items = ['From powerful', 'product design', 'to web and mobile', 'apps'];
const items2 = ['Tell us which series you are into and', 'we’ll tell you what you need'];
const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
const config = { mass: 5, tension: 2000, friction: 200 }
const currencies = [
    {
        value: 'Seed money',
        label: 'Seed money',
    },
    {
        value: 'Series A',
        label: 'Series A',
    },
    {
        value: 'Series B',
        label: 'Series B',
    },
];
function rand() {
    return Math.round(Math.random() * 20) - 10;
}
  
function getModalStyle() {
    const top = 50 + rand();
    const left = 50;    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      display: 'inline-grid',
    },
}));

const Home=()=>{

    const [toggle, set] = useState(true)
    const [toggleTitle, setToggleTitle ] = useState(true)
    const trail = useTrail(items.length, {
        config,
        opacity: toggle ? 1 : 0,
        x: toggle ? 0 : 20,
        height: toggle ? 45 : 0,
        from: { opacity: 0, x: 20, height: 0 },
    })
    const trailTitle = useTrail(items.length, {
        config,
        opacity: toggleTitle ? 1 : 0,
        x: toggleTitle ? 0 : 20,
        height: toggleTitle ? 45 : 0,
        from: { opacity: 0, x: 20, height: 0 },
    })
    const [currency, setCurrency] = React.useState('EUR');
    const [form, setForm] = useState({
        full_name   : "",
        email       : "",
        whatsapp    : "", 
        serie       : ""
    });
    const [btndisabled, Setbtndisabled] = useState(true);
    const [textError, SettextError] = useState({
        full_name   : "",
        email       : "",
        whatsapp    : "", 
        serie       : ""
    });
    const [error, setError] = useState({
        full_name   : false,
        email       : false,
        whatsapp    : false, 
        serie       : false
    }); 

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const [state, setState] = useState(true)
    const { x } = useSpring({ from: { x: 0 }, x: state ? 1 : 0, config: { duration: 1000 } })

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setForm({
            full_name   : "",
            email       : "",
            whatsapp    : "", 
            serie       : ""
        })
    };
    const ValidLetras = (nombre,valor) =>
    {
        if(nombre && !/^[a-zA-Z*\s]+$/i.test(valor))
        {
            textError[nombre] = "Value cannot have numeric characters"
            error[nombre] =true
        }
        else{
            textError[nombre] = ""
            error[nombre] =false
        }
        eBtn()
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name] : e.target.value,
        })
        switch(e.target.name)
        {
            case "email":
                ValidEmail()
            break;
            case "full_name": 
                ValidLetras("full_name", form.full_name)
            break;
            case "whatsapp": 
                ValidPhone()
            break;
            default:
                //ValidLetras(e.target.name, e.target.value)
            break;
        }
    }

    const ValidPhone = () =>
    {
        if((form.whatsapp).length >= 10 || "whatsapp" && !/^[0-9]+$/i.test(form.whatsapp)) {
            textError.whatsapp = "Please verify your phone number"
            error.whatsapp =true
        }
        else
        {
            textError.whatsapp = ""
            error.whatsapp = false
        }
        eBtn()
    }

    const ValidPhone2 = () =>
    {
        if((form.whatsapp).length !== 10|| "whatsapp" && !/^[0-9]+$/i.test(form.whatsapp)) {
            textError.whatsapp = "Please verify your phone number"
            error.whatsapp =true
        }
        else
        {
            textError.whatsapp = ""
            error.whatsapp = false
        }
        eBtn()
    }

    const ValidEmail = () => 
    {
        if (form.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) 
        {
            textError.email = "Wrong email"
            error.email =true
        }
        else
        {
            textError.email = ""
            error.email = false
        }
        eBtn()
    }

    const eBtn = () =>
    {
        if((form.email.length !== "" && !error.email) && 
           (form.full_name.length !== "" && !error.full_name) &&  
           (form.whatsapp.length !== "" && !error.whatsapp) &&
           (form.serie.length !== "" && !error.serie))
        {
            Setbtndisabled(false);
        }
        else
        {
            Setbtndisabled(true);
        }
    }

    const guardar = e =>
    {
        e.preventDefault()
        ValidPhone2();
        if(!error.email && !error.full_name && !error.whatsapp && !error.serie)
        {
           var template_params = {
            "nombre": form.full_name,
            "email": form.email,
            "telefono": form.whatsapp,
            "serie": form.serie
        }

        var service_id = "default_service";
        let user_id="user_zXxs8NJSj4QS32PtsdAZL";
        var template_id = "envio_ali";
        emailjs.send(service_id, template_id, template_params,user_id).then((response) => {
            console.log("SUCCESS!", response.status, response.text);
            handleOpen()
            //setContact(frmContact);
            //setShowMessage(true);
        }, (err) => {
            console.log("FAILED...", err);
        });
        }
        else{
            
        }      
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <ParagraphOne mt="0px" fontSize="1.5rem" ta="center" mt="5%" mb="5%">Thanks for being awesome!</ParagraphOne>
            <ParagrapTwo id="simple-modal-description" fontSize="15px" mb="0px" ta="center">
                We have received your message and would</ParagrapTwo>
            <ParagrapTwo id="simple-modal-description" fontSize="15px" mb="0px" ta="center">
                like to thank you for writing to us. We will</ParagrapTwo>
            <ParagrapTwo id="simple-modal-description" fontSize="15px" mb="5%" ta="center">
                reply by email as soon as possible.</ParagrapTwo>
            <Container width="100%" ta="center">
                <Button  onClick={handleClose}  margin="auto">Accept</Button>
            </Container>
            
        </div>
    );
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [animate, setAnimate] = useState(false); 

    const handleScroll = () => {
        setScrollY(window.scrollY);
        console.log(scrollY);
        if(scrollY < 3650) {
            set(false)
        }
        else
        {
            setAnimate(true)
            set(true)
            console.log(animate);
            
        }
        if(scrollY >= 4400)
        {
            setState(true);
        }
        else{
            setState(false)
        }

    }
    const [isHover,setIsHover]=useState(false);
    
    useEffect(() =>{
        window.onscroll = () => handleScroll();
    })
    const [props, setprops] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))
    const [props2, setprops2] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))
    const [props3, setprops3] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))
    const [props4, setprops4] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))
    const [props5, setprops5] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))
    const [props6, setprops6] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))
    const [props7, setprops7] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))
    const [props8, setprops8] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 500, friction: 60 } }))

    
    useEffect(() => {
        setTimeout(() => {
            setOpenDialog(true)
        }, 3000);
    }, [])
    
    function getDialogStyle() {
        return {
            top: `${50}%`,
            left: `${50}%`,
            transform: `translate(-${50}%, -${50}%)`,
            border: 'none',
        };
    }
    
    const deviceDialogStyles = () => {
        if(isMobile) {
            return (
                {
                    root: {
                        position: 'absolute',
                        width: '85vw',
                        height: '88vh',
                        backgroundColor: '#FFA800',
                        backgroundImage: 'url("./Group 381.png")',
                        border: 'none',
                        outline: 'none',
                        display: "flex",
                        flexDirection: "column",
                        alignItems: 'center',
                        padding: "15px 15px"
                    }
                }
            )
        }
        return (
            {
                root: {
                    position: 'absolute',
                    width: '50vw',
                    height: '60vh',
                    backgroundColor: '#FFA800',
                    backgroundImage: 'url("./Group 381.png")',
                    border: 'none',
                    outline: 'none',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    padding: "50px 60px"
                }
            }
        )
    }

    const useDialogStyles = makeStyles(() => (
        deviceDialogStyles()
    ));

    const useBackdropStyles = makeStyles({
            root: {
                backgroundColor: "rgba(14,29,57,0.7)",
                backdropFilter: "blur(5px)"
            }
    })
    
    const backdropClasses = useBackdropStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogStyle] = React.useState(getDialogStyle);
    const dialogClasses = useDialogStyles()
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const bodyDialog = () => (
        <div className={dialogClasses.root} style={dialogStyle}> 
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "auto"}}>
            <h1 style={{fontFamily: "DM Sans", fontSize: "40px", margin: "0px 0 20px",  textAlign: "center"}}>¡Evolucionamos!</h1>
            <p style={{fontFamily: "DM Sans", fontSize: "18px",  textAlign: "center"}}>Nos emociona que seas parte de esto...</p>
            <h3 style={{fontFamily: "DM Sans", fontSize: "24px", margin: "0",  textAlign: "center"}}>Alimx evoluciona a Rocket Code</h3>
            <p 
            style={{fontFamily: "DM Sans", fontSize: "18px", textAlign: "center"}}>
            Somos el mismo equipo que conoces pero con una nueva imagen, que gracias a tu confianza hemos logrado.</p>
            <a 
            style={{fontFamily: "DM Sans", fontSize: "24px", color: "inherit", fontWeight: "700", margin: "30px", textDecoration: "none", borderBottom: "1px solid white",  textAlign: "center"}} 
            href="https://therocketcode.com/letter">Palabras de nuestro CEO</a>
            <BtnGeneral href="https://therocketcode.com/" style={{margin: "5px 30px 20px"}}>DESCUBRIR ROCKET CODE</BtnGeneral>
            </div>
        </div>
    )

    return (
    <Fragment>
        <Modal onClose={handleCloseDialog} open={openDialog} BackdropComponent={Backdrop} BackdropProps={{classes: {root: backdropClasses.root}}}>{bodyDialog()}</Modal>
        <ButtonWhatsApp borderRadius="0 25px 25px 0" position="fixed" bottom="2%" textAlign="end" padding="5px 12px" isHover={isHover} onEnter={()=>setIsHover(true)} onLeave={()=>setIsHover(false)} onFocus={()=>setIsHover(true)} onBlur={()=>setIsHover(false)} content={<h2 style={{margin:"0px"}}>55 2141 9331</h2>} >
            <LogoWhats/>
        </ButtonWhatsApp>
        <Main id="divStart" className="d-flex justify-content-center align-items-center">
            <Container mb="3%" mt="2%" mtMobile="0%">
                <Col6 margin="auto">
                    {trailTitle.map(({ x, height, ...rest }, index) => (
                        <animated.div
                            key={items[index]}
                            className="trails-text"
                            style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}>
                            <animated.div style={{ height }}>{items[index]}</animated.div>
                        </animated.div>
                    ))}
                    <ParagrapTwo fontSize="20px" mb="10%" mt="5%" mtMobile="10%" fontSizeMobile="23px" >We are obsessed with your success</ParagrapTwo>
                    <ButtonLink href="#divForm" display="flex" padding="10px" alingItems="center" justifyContent="center">REQUEST A QUOTE</ButtonLink>
                </Col6>
                <Col6>
                    <Image src={ImageLogo} mlMobile="25%!important" mtMobile="10%!important" mbMobile="10%!important"></Image>
                </Col6>
            </Container>
        </Main>
        <Main background="rgb(248,249,251)">
            <Container mb="3%" mt="3%">
                <Col6>
                    <Image src={ImageTwo} mlMobile="25%!important" mtMobile="10%!important" mbMobile="10%!important"></Image>
                </Col6>
                <Col6 margin="auto">
                    <ParagrapTwo fontSize="20px" fontWeight="bold">OUR VALUE PROPOSAL</ParagrapTwo>
                    <ParagraphOne fontSize="31px" lh="35px" lhmobile="33px">We are the smartest way to</ParagraphOne>
                    <ParagraphOne mt="0px" fontSize="31px" lh="35px" lhmobile="33px"> have a super performing </ParagraphOne>
                    <ParagraphOne mt="0px" fontSize="31px" lh="35px" lhmobile="33px">product and tech team</ParagraphOne>
                    <ParagrapTwo fontSize="20px" ta="justify" mt="5%" mbMobile="10%" mtMobile="10%" widthmax="60%" widthmax2="55%" widthmax3="90%" widthmax4="85%" widthmax5="80%" widthmax6="75%">Everyone working on your project is fully invested in your success. 
                                                We understand your goals to make them our own. 
                                                We strongly believe it is the only way to have long-term partnerships.
                    </ParagrapTwo>
                </Col6>
                
            </Container>
        </Main>
        <Main >
            <Container mb="5.7%" mt="9%" alignItems="flex-end" justifyContent="flex-end" className="container-card" width="85%">
            <Container width="60%" ml="0px">
                <Col12 pt="5%">
                    <ParagrapTwo fontSize="20px" fontWeight="bold">WHAT WE DO</ParagrapTwo>
                    <ParagraphOne fontSize="35px" mt="25px">Product and Tech</ParagraphOne>
                    <ParagraphOne fontSize="35px" mt="-5px">development services</ParagraphOne>
                    <ParagrapTwo fontSize="20px" ta="justify" mt="3%" mtMobile="10%" >Our commitment to your success goes through a broad set of skills. Our product and 
                    </ParagrapTwo>
                    <ParagrapTwo fontSize="20px" ta="justify" mt="-5px!important" mb="0px">tech experts will find innovative solutions to help you win the game!</ParagrapTwo>
                </Col12>
            </Container>
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops({ xys: [0, 0, 1] })}
                    style={{ transform: props.xys.interpolate(trans) }}>
                    <HexagonoCard size={useSizeDimmensions()} className="hexagono-card">
                        <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                            <Image src={Imagebulb} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial"></Image>
                            <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">Product strategy</ParagraphOne>
                            <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">Our strategists will help you understand the market, 
                                                    the user and the product for you to take over the industry.
                            </ParagrapTwo>
                        </HexagonoCard>
                    </HexagonoCard>
                </animated.div>
            </Container>
        </Main>
        <Main>
            <Container mb="5.7%" justifyContent="flex-end" className="container-card" width="85%">
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops3({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops3({ xys: [0, 0, 1] })}
                    style={{ transform: props3.xys.interpolate(trans) }}>
                        <HexagonoCard size={useSizeDimmensions()} mR={useSizeDimmensions()/2} className="hexagono2 hexagono-card">
                            <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                                <Image src={Imagedesk} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial" ></Image>
                                <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">Web development</ParagraphOne>
                                <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">We know that web apps are still fundamental
                                                             to really own the market so we do it amazingly.
                                </ParagrapTwo>
                            </HexagonoCard>
                        </HexagonoCard>
                </animated.div> 
            </Container>
        </Main>
        <Main id="divAbout">
            <Container mb="5.7%" mt="0%" justifyContent="flex-end" className="container-card" width="85%">
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops5({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops5({ xys: [0, 0, 1] })}
                    style={{ transform: props5.xys.interpolate(trans) }}>
                        <HexagonoCard size={useSizeDimmensions()} mR={useSizeDimmensions()} className="hexagono hexagono-card">
                            <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                                <Image src={Imagegrafic} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial"></Image>
                                <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">Data & Analytics</ParagraphOne>
                                <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">The first way to improve is to have clear goals and 
                                                           the way to measure them. This is our speciality.</ParagrapTwo>
                            </HexagonoCard>
                        </HexagonoCard>
                </animated.div>
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops2({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops2({ xys: [0, 0, 1] })}
                    style={{ transform: props2.xys.interpolate(trans) }}>
                        <HexagonoCard size={useSizeDimmensions()} className="hexagono-card">
                            <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                                <Image src={Imagepen} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial"></Image>
                                <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">Product design</ParagraphOne>
                                <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">Using the rigth methodologies, we put the user first 
                                                                and create hypothesis on how to make their lives better through 
                                                                the digital approach.
                                </ParagrapTwo>
                            </HexagonoCard>
                        </HexagonoCard>
                </animated.div>
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops4({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops4({ xys: [0, 0, 1] })}
                    style={{ transform: props4.xys.interpolate(trans) }}>
                        <HexagonoCard size={useSizeDimmensions()} className="hexagono-card">
                            <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                                <Image src={Imagemobile} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial"></Image>
                                <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">App development</ParagraphOne>
                                <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">We can transform your idea to a downloadable app for iOS and Android market.</ParagrapTwo>
                            </HexagonoCard>
                        </HexagonoCard>
                </animated.div>
            </Container>
        </Main>
        <Main id="divAbout">
            <Container mb="10%" mt="0%" justifyContent="flex-end" width="85%" className="container-card-2">      
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops6({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops6({ xys: [0, 0, 1] })}
                    style={{ transform: props6.xys.interpolate(trans) }}>
                        <HexagonoCard size={useSizeDimmensions()} className="hexagono-card">
                            <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                                <Image src={Imagecircle} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial"></Image>
                                <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">API & Integrations</ParagraphOne>
                                <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">We build +500 optimal and scalable 
                                                           Integrations both for external and internal use.</ParagrapTwo>
                            </HexagonoCard>
                        </HexagonoCard>
                </animated.div> 
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops7({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops7({ xys: [0, 0, 1] })}
                    style={{ transform: props7.xys.interpolate(trans) }}>
                        <HexagonoCard size={useSizeDimmensions()} className="hexagono-card">
                            <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                                <Image src={Imageprem} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial"></Image>
                                <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">Testing & Quality</ParagraphOne>
                                <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">Understand users behavior is critical, which is why
                                                         we have developed a super efficient Q&A process.</ParagrapTwo>
                            </HexagonoCard>
                        </HexagonoCard>
                </animated.div> 
                <animated.div
                    onMouseMove={({ clientX: x, clientY: y }) => setprops8({ xys: calc(x, y) })}
                    onMouseLeave={() => setprops8({ xys: [0, 0, 1] })}
                    style={{ transform: props8.xys.interpolate(trans) }}>
                        <HexagonoCard size={useSizeDimmensions()} mR={useSizeDimmensions()/2} className="hexagono-card hexagono2">
                            <HexagonoCard size={useSizeDimmensions()-0.01} zIndex={2} bgColor="#fff">
                                <Image src={Imagecall} width="25%" heigth="auto" heigthMobile="auto"  widthMobile="25%" margin="initial"></Image>
                                <ParagraphOne fontSize="14px" mt="0px" fontSizeMobile="16px" ta="center">Project Management</ParagraphOne>
                                <ParagrapTwo fontSize="12px" lh="15px" fontSizeMobile="12px" ta="center">Efficient collaboration, total transparency and secure development of the fundamentals of our DNA.</ParagrapTwo>
                            </HexagonoCard>
                        </HexagonoCard>
                </animated.div>
            </Container>
        </Main>
        <Main background="rgb(248,249,251)">
            <Container mb="3%" mt="3%">
                <Col6>
                    <Image src={ImageThre} mtMobile="20%!important" mlMobile="20%!important"></Image>
                </Col6>
                <Col6 margin="auto">
                    <ParagrapTwo fontSize="20px" fontWeight="bold">OUR FOOTPRINT</ParagrapTwo>
                    <ParagraphOne fontSize="35px" lh="33px" lhmobile="30px">Our devotion for our</ParagraphOne>
                    <ParagraphOne mt="0px" fontSize="35px" lh="33px" >clients is resonating</ParagraphOne>
                    <ParagrapTwo width="80%" fontSize="20px" ta="justify" mt="10%" mtMobile="10%" mbMobile="5%">With over 5 years building amazing products, 
                                                              we have helped our costumers to achieve their goals
                                                              and they LOVE us, the reason is simple...
                    </ParagrapTwo>
                    <ParagraphOne fontSize="15px" lh="15px" lhmobile="15px"  mbMobile="20%" fontSizeMobile="15px">WE DELIVER</ParagraphOne>
                </Col6>
            </Container>
        </Main>
        <Main>
            <Container>
                <Col12 pt="5%" ta="center">
                    <ParagraphOne mt="25px" ta="center">We have helped <SpanCoru>Coru</SpanCoru></ParagraphOne>
                    <ParagraphOne mt="0px" ta="center" lh="50px">to serve 2.5M users</ParagraphOne>
                    <ParagrapTwo fontSize="20px" ta="justify" ta="center" mb="0px" mt="3%" mtMobile="10%" mbMobile="15%" >The most powerful financial marketplace in Mexico, serving millions of users and top providers.
                    </ParagrapTwo>
                    <Image src={ImagemobileCoru} margin="auto" width="50%" bottom="5%" top="5%" mbMobile="20%!important"></Image>
                </Col12>
            </Container>
        </Main>
        <Main background="rgb(248,249,251)">
            <Container>
                <Col12 pt="5%" ta="center">
                    <ParagraphOne mt="25px" ta="center" lhmobile="35px" >We have helped <SpanCoru>Prana Power</SpanCoru></ParagraphOne>
                    <ParagraphOne mt="0px" ta="center" lhmobile="35px" >to create the first online B2C</ParagraphOne>
                    <ParagraphOne mt="0px" ta="center" lhmobile="35px">calculation algorithm</ParagraphOne>
                    <ParagrapTwo fontSize="20px" ta="justify" ta="center" mb="0px" mt="3%" mtMobile="10%" mbMobile="0%">Energy solutions, develop, own, operate and mantain
                    </ParagrapTwo>
                    <ParagrapTwo fontSize="20px" ta="justify" ta="center" mtMobile="0%" mbMobile="20%!important">power generation assets.
                    </ParagrapTwo>
                    <Image src={ImagePranaCompu} margin="auto" width="50%" bottom="5%" top="0%" mbMobile="20%!important"></Image>
                </Col12>
            </Container>
        </Main>
        <Main>
            <Container>
                <Col12 pt="5%" ta="center">
                    <ParagraphOne mt="25px" ta="center" lh="33px" lhmobile="30px">We have helped <SpanCoru>Creze</SpanCoru></ParagraphOne>
                    <ParagraphOne mt="0px" ta="center" lh="33px" lhmobile="30px">to create a world class</ParagraphOne>
                    <ParagraphOne mt="0px" ta="center" lh="33px" lhmobile="30px">lender platform</ParagraphOne>
                    <ParagrapTwo fontSize="20px" ta="justify" ta="center" mb="0px" mt="3%" mtMobile="20%" mbMobile="0%" >Most efficient and coustomer focused journey
                    </ParagrapTwo>
                    <ParagrapTwo fontSize="20px" ta="justify" ta="center" mbMobile="20%">for SME lending.
                    </ParagrapTwo>
                    <Image src={ImageCrezeTablet} margin="auto" width="50%" bottom="5%" top="0%" widthMobile="80%" mbMobile="15%!important"></Image>
                    <ParagrapTwo fontSize="20px" ta="justify" mb="3%" mtMobile="15%important">FROM STARTUP TO STARTUP
                    </ParagrapTwo>
                    {trail.map(({ x, height, ...rest }, index) => (
                        <animated.div
                            key={items2[index]}
                            className="trails-text2"
                            style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}>
                            <animated.div style={{ height }}>{items2[index]}</animated.div>
                        </animated.div>
                    ))}

                    <ParagrapTwo fontSize="20px" ta="justify" mt="-7%" mb="0px" mtMobile="-10%!important">We speak your language and we understand your
                    </ParagrapTwo>
                    <ParagrapTwo fontSize="20px" ta="justify" mt="0px" mb="0px">needs, which is why we have develped different 
                    </ParagrapTwo>
                    <ParagrapTwo fontSize="20px" ta="justify" mt="0px" mb="0px">programs that will really fit you.
                    </ParagrapTwo>
                </Col12>
            </Container>
        </Main>
        <Main>
            <Container mb="5%" mt="5%" mbMobile="20%!important">
                <Col3Contor ta="left" border="2px solid rgb(187,38,163)">
                    <Image src={Imagevinetas11} width="auto" heigth="60px" widthMobile="25%" margin="inherit" marginMobile="inherit"></Image>
                    <ParagraphOne fontSize="16px" mt="0px" lh="35px" fontSizeMobile="20px">Seed money</ParagraphOne>
                    <ParagrapTwo fontSize="15px" lh="18px" lhmobile="15px">Our strategies will help you understand the market, the user and the product for you to take over the industry.
                    </ParagrapTwo>
                    <Linktext>REQUEST A QUOTE <Spanticons>></Spanticons></Linktext>
                </Col3Contor>
                <Col3Contor ta="left" border="2px solid rgb(187,38,163)">
                    <Image src={Imagevinetas10} width="auto" heigth="60px" widthMobile="25%"  margin="inherit" marginMobile="inherit"></Image>
                    <ParagraphOne fontSize="16px" mt="0px" lh="35px" fontSizeMobile="20px">Series A</ParagraphOne>
                    <ParagrapTwo fontSize="15px" lh="18px" lhmobile="15px">Our strategies will help you understand the market, the user and the product for you to take over the industry.
                    </ParagrapTwo>
                    <Linktext>REQUEST A QUOTE <Spanticons>></Spanticons></Linktext>
                </Col3Contor>                
                <Col3Contor ta="left" border="2px solid rgb(187,38,163)"> 
                    <Image src={Imagevinetas13} width="auto" heigth="60px" widthMobile="25%"  margin="inherit" marginMobile="inherit"></Image>
                    <ParagraphOne fontSize="16px" mt="0px" lh="35px" fontSizeMobile="20px">Series B</ParagraphOne>
                    <ParagrapTwo fontSize="15px" lh="18px" lhmobile="15px">Our strategies will help you understand the market, the user and the product for you to take over the industry.
                    </ParagrapTwo>
                    <Linktext>REQUEST A QUOTE <Spanticons>></Spanticons></Linktext>
                </Col3Contor> 
                <Col3Contor ta="left">
                    <Image src={Imagevinetas12} width="auto" heigth="60px" widthMobile="25%"  margin="inherit" marginMobile="inherit"></Image>
                    <ParagraphOne fontSize="16px" mt="0px" lh="35px" fontSizeMobile="20px">To infinity and beyond</ParagraphOne>
                    <ParagrapTwo fontSize="15px" lh="18px" lhmobile="15px">Our strategies will help you understand the market, the user and the product for you to take over the industry.</ParagrapTwo>
                    <Linktext>REQUEST A QUOTE <Spanticons>></Spanticons></Linktext>
                </Col3Contor> 
            </Container>
        </Main>
        <Main id="divForm" background="rgb(248,249,251)">
            <Container mb="3%" mt="3%">
                <Col6 margin="auto">
                    <Image src={ImageCoffe} mtMobile="10%!important" mlMobile="20%!important" top="10%" width="auto"></Image>
                </Col6>
                <Col6 margin="auto">
                    <ParagraphOne fontSize="35px" lh="33px" mt="20px" pl="1%">
                    <animated.div
                        style={{
                                opacity: x.interpolate({ range: [0, 1], output: [0.3, 1] }),
                                transform: x
                                .interpolate({
                                range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
                                output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1]
                                })
                                .interpolate(x => `scale(${x})`)
                            }}>
                        Let’s have coffee, shall we?
                    </animated.div>
                    </ParagraphOne>
                    <form autoComplete="off" onSubmit={guardar}>
                        <Col12>
                            <TextField id="full_name"  name="full_name" error ={error.full_name} label="FULL NAME" type="text" variant="outlined" fullWidth value={form.full_name}  onChange={handleChange} helperText={textError.full_name} />
                        </Col12>
                        <Col12>
                            <TextField id="email"  name="email" error ={error.email} label="EMAIL"  type="email" variant="outlined" fullWidth value={form.email}  onChange={handleChange} helperText={textError.email} />
                        </Col12>
                        <Col12>
                            <TextField id="whatsapp" name="whatsapp" inputProps={{maxLength: 10}} onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }} error ={error.whatsapp} label="WHATSAPP" type="tel" variant="outlined" fullWidth value={form.whatsapp}  onChange={handleChange} helperText={textError.whatsapp} />
                        </Col12>
                        <ParagrapTwo  pl ="1%" fontSize="20px" ta="justify" ta="left" mb="0px" mt="6%" mtMobile="10%" mbMobile="0%" color="#000b49">BUSINESS NEEDS
                        </ParagrapTwo>
                        <Col12>
                            <TextField id="serie" name="serie" error ={error.serie} select label="Business" value={currency} value={form.serie} helperText={textError.serie} onChange={handleChange} variant="outlined" fullWidth>
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                            </TextField>
                        </Col12>
                        <Col12 display="flex">
                            <Image src={Imageboard} width="15%" widthMobile="30%!important" mlMobile="30%!important"></Image>
                            <Button disabled={btndisabled}  width="70%" ml="10%" widthMobile="100%" float="right">CONTACT ME NOW</Button>
                        </Col12>
                    </form>
                </Col6>
            </Container>
        </Main>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>
    </Fragment>
    );
}

export default Home;